<template>
    <div class="page-content">
        <div class="grid">
            <div class="page-list-body">
                <div class="row no-gutters page-list-header">
                    <div class="col l-6 m-6 c-12">
                        <h4 class="page-list-title"><md-icon>list_alt</md-icon> <span>Tạm ứng làm hàng</span></h4>
                    </div>
                    <div class="col l-6 m-6 c-12">
                        <div class="page-list-action">
                            <md-button @click="switchView()" class="md-raised md-view btn-add">ĐNTƯ</md-button>
                            <md-button class="md-raised md-view btn-add btn-active">Chi tiết 2</md-button>
                            <md-button v-shortkey="['ctrl', 'm']" @shortkey="exportExcel()" @click="exportExcel()" class="md-raised md-primary btn-add">Export<md-tooltip>Xuất file Excel</md-tooltip></md-button>
                        </div>
                    </div>
                </div>
                <div class="form-body">
                    <div class="wrapper">
                        <div class="row no-gutters">
                            <div class="col l-4 m-4 c-12">
                                <div class="left">
                                    <img src="../../../assets/images/logo.png" />
                                </div>
                            </div>
                            <div class="col l-8 m-8 c-12">
                                <div class="center">
                                    <div class="title"><h2>TẠM ỨNG LÀM HÀNG</h2></div>
                                    <div class="code"><span class="label">Số: </span><span>{{entity.advancePayment.advancePaymentCode}}</span></div>
                                    <div class="date"><span class="label">Ngày: </span><span>{{entity.advancePayment.advancePaymentDate}}</span></div>
                                </div>
                            </div>
                        </div>
                        <div class="row no-gutters" style="padding-top:10px;">
                            <div class="col l-12 m-12 c-12">
                                <div class="line"> 
                                    <label>Mã phiếu: </label>
                                    <div>{{entity.advancePayment.advancePaymentCode}}</div>
                                </div>
                                <div class="line"> 
                                    <label>Người lập phiếu: </label>
                                    <div>{{entity.advancePayment.staff.fullName}}</div>
                                </div>
                                <div class="line-2">
                                    <div class="line"> 
                                        <label>Ghi chú: </label>
                                        <div>{{entity.advancePayment.note}}</div>
                                    </div>
                                </div>
                                <div class="line-2">
                                    <div class="line"> 
                                        <label>Số tiền tạm ứng: </label>
                                        <div style="font-weight: bold;">{{ formatNumber(entity.advancePayment.pay)}} VNĐ</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div class="row no-gutters">
                            <div class="col l-12 m-12 c-12" style="padding-top:20px; overflow: auto;">
                                <table class="table-1" style="width: 3000px;">
                                    <thead>
                                        <tr>
                                            <th rowspan="2" style="width:80px;">STT</th>
                                            <th rowspan="2" style="width:200px;">Mã dự toán</th>
                                            <th rowspan="2" style="width:120px;">Ngày dự toán</th>
                                            <th rowspan="2" style="width:150px;">Khách hàng</th>
                                            <th rowspan="2" style="width:300px;">Số bill/booking</th>
                                            <th :colspan="entity.headerUnit.length" style="background-color: #FCDE70;">Số lượng con't</th>
                                            <th v-if="entity.headerFee.filter(x => x.type == 1).length > 0" :colspan="entity.headerFee.filter(x => x.type == 1).length" style="background-color: #81DAE3;">Chi phí</th>
                                            <th v-if="entity.headerFee.filter(x => x.type == 2).length > 0" :colspan="entity.headerFee.filter(x => x.type == 2).length" style="background-color: #81DAE3;">Chi hộ</th>
                                            <th v-if="entity.headerFee.filter(x => x.type == 3).length > 0" :colspan="entity.headerFee.filter(x => x.type == 3).length" style="background-color: #81DAE3;">Dịch vụ</th>
                                            <th v-if="entity.headerFee.filter(x => x.type == 4).length > 0" :colspan="entity.headerFee.filter(x => x.type == 4).length" style="background-color: #81DAE3;">Lương lái xe</th>
                                            <th v-if="entity.headerFee.filter(x => x.type == 5).length > 0" :colspan="entity.headerFee.filter(x => x.type == 5).length" style="background-color: #81DAE3;">Cước vận chuyển</th>
                                            <th v-if="entity.headerFee.filter(x => x.type == 6).length > 0" :colspan="entity.headerFee.filter(x => x.type == 6).length" style="background-color: #81DAE3;">Vetc</th>
                                            <th v-if="entity.headerFee.filter(x => x.type == 7).length > 0" :colspan="entity.headerFee.filter(x => x.type == 7).length" style="background-color: #81DAE3;">Cược sửa chữa</th>
                                            <th v-if="entity.headerFee.filter(x => x.type == 8).length > 0" :colspan="entity.headerFee.filter(x => x.type == 8).length" style="background-color: #81DAE3;">Cược con't</th>

                                            <th rowspan="2" style="width:150px; background-color: #FF8225;">Thành tiền</th>
                                        </tr>
                                        <tr>
                                            <th v-for="(unit, ind) in entity.headerUnit" :key="'h-unit-' + ind" style="width:80px; background-color: #FCDE70;">{{ unit }}</th>
                                            <th v-for="(fee, indFee) in entity.headerFee" :key="'fee-' + indFee" style="width:120px; background-color: #81DAE3;">{{ fee.feeName }}</th>
                                        </tr>
                                    </thead>
                                    <tbody v-for="(item, index) in entity.details" :key="'item-' + index">
                                        <tr>
                                            <td style="text-align: center; padding: 5px 10px; border: 1px solid #e0e0e0;"><span style="font-weight:bold;">{{ index + 1 }}</span></td>
                                            <td>{{ item.estimateCode }}</td>
                                            <td>{{ item.estimateDate }}</td>
                                            <td>{{ item.customerName }}</td>
                                            <td>{{ item.noBooking }}</td>
                                            <td v-for="(unit, ind) in entity.headerUnit" :key="'b-unit-' + ind" style="width:120px; text-align: center;">
                                                <span v-if="item.unitList.filter(x => x.unitName == unit)[0] !== undefined">
                                                    {{ item.unitList.filter(x => x.unitName == unit)[0].unitAmount }}
                                                </span>
                                                <span v-else>0</span>
                                            </td>
                                            <td v-for="(fee, ind) in entity.headerFee" :key="'b-unit-' + ind" style="width:150px; text-align: right;">
                                                <span v-if="item.feeList.filter(x => x.feeId == fee.feeId && x.type == fee.type)[0] !== undefined">
                                                    {{ formatNumber(item.feeList.filter(x => x.feeId == fee.feeId && x.type == fee.type)[0].feeAmount) }}
                                                </span>
                                                <span v-else>0</span>
                                            </td>
                                            <td style="text-align: right;">{{ formatNumber(item.amount) }}</td>
                                        </tr>
                                    </tbody>
                                    <tbody>
                                        <tr>
                                            <td :colspan="entity.headerUnit.length + entity.headerFee.length + 5" style="text-align: right;"><b>Tổng cộng</b></td>
                                            <td style="text-align: right; font-weight: bold;">{{ formatNumber(entity.advancePayment.pay) }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
   </div>
</template>

<script>
    import orderAdvancePaymentService from '@/api/orderAdvancePaymentService';
    import messageBox from '@/utils/messageBox';
    import { mapActions } from 'vuex';
    import { APP_CONFIG } from '@/utils/constants'

    export default ({
        metaInfo: {
            title: 'Tạm ứng làm hàng'
        },
        data() {
            return {
               title: 'Tạm ứng làm hàng',
               id: 0,
               submitted: false,
               loadding: false,
               details: [],
               entity: { id: 0, advancePayment: { advancePaymentCode: '', advancePaymentDate: '', staff: { fullName: '' } } },
            }
        },
        created(){
            this.id = this.$route.params.id;
            if(this.id > 0){
                this.getById();
            }
        },
        methods: {
            ...mapActions('common', ['setLoading']),

            exportExcel(){
                const link = document.createElement('a')
                link.href = APP_CONFIG.apiUrl + "/order-advance-payment/export-excel?id=" + this.id;
                link.target = '_blank';
                link.click()
                URL.revokeObjectURL(link.href)
            },

            switchView(){
                this.$router.push('/order-advance-payment/view/' + this.id);
            },

            getById(){
                this.setLoading(true);
                orderAdvancePaymentService.getView(this.id).then((response) => {
                    if(response.statusCode == 200){
                        this.entity = response.data;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },
        },
    })

</script>

<style scoped>
    .table-1 tr th {
        text-wrap: wrap;
    }
    .btn-active {
        background-color: #08C2FF !important;
    }
    .wrapper {
        margin: 0 auto;
        width: 100%;
        padding-top: 20px;
        padding-bottom: 30px;
    }
    .header {
        display: flex;
        justify-content: space-around;
        border: 1px solid #999;
        position: relative;
    }
    .left, .center, .right {
        height: 100px;
    }
    .left {
        border-top: 1px solid #999;
        border-left: 1px solid #999;
        border-bottom: 1px solid #999;
        border-right: 1px solid #999;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .left img{
        height: 70px;
        width: auto;
    }
    .center {
        border-top: 1px solid #999;
        border-bottom: 1px solid #999;
        border-right: 1px solid #999;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .center .title {
        display: flex;
        
    }
    .center .title h2 {
        font-size: 22px;
        text-transform: uppercase;
        font-weight: bold;
    }
    .center .code, .center .date {
        font-size: 14px;
    }
    .label {
        font-weight: bold;
    }
    .approve {
        display: flex;
        justify-content: space-around;
        padding-top: 20px;
    }
    .approve-item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .approve-item .title {
        font-size: 20px;
        font-weight: bold;
        text-transform: uppercase;
    }
    .approve-item .approve-status {
        padding: 20px 0;
    }
    .approve-item .approve-status .md-chip {
        font-size: 14px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .approve-item .approve-status i{
        color: #00d25b;
        font-size: 25px;
        padding-right: 5px;
    }
    .approve-item .name {
        font-size: 14px;
        font-weight: bold;
    }
    .line {
        display: flex;
        padding-top: 5px;
        align-items: center;
    }
    .line label {
        font-weight: bold;
        padding-right: 10px;
        font-size: 14px;
    }
    .line span{
        font-size: 14px;
        font-weight: bold;
    }
    .line div {
        font-size: 14px;
    }
    .line-item {
        padding-right: 5px;
    }
    .line-item::after {
        content: ',';
    }
    .line-item:last-child::after {
        content: '';
    }
    .line-2 {
        display: flex;
    }
    .line-2 .line:first-child {
        padding-right: 40px;
    }
    .line-3 {
        display: flex;
        justify-content: space-between;
    }
    .table-1 {
        width: 100%;
    }
    .stt {
        text-align: center;
    }
    .table-1 td, .table-1 th {
        font-size: 14px;
    }
    .table-1 {
        min-height: 200px !important;
    }
    .currency {
        text-align: right;
    }
    b {
        font-weight: bold;
        font-size: 14px;
    }
</style>
